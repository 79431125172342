<template lang="pug">
  .vue-combat
    menu-button
    img.w-100.p-absolute.bg(src="@/assets/combat/fond.jpg" srcset="@/assets/combat/fond@2x.jpg 2x")
    template(v-if="status === 'play'")
      h2.text-block {{ $t('combat.titre') }}
      svg.p-absolute(viewBox="0 0 1920 1080" width="100%" height="100%")
        g#case_01.case(@click="changeSymbol(0)")
          path(d="M342.09,542L659.29,542L625.79,786L419.42,786L342.09,542Z" style="fill:white;stroke:black;stroke-width:8px;")
          text.symbol(x="435" y="760") {{ symbols[0] }}
          text.number(x="495" y="510") 1
        g#case_02.case(@click="changeSymbol(1)")
          path(d="M691.912,542.21L907.005,542.21L983.202,786.182L659.286,786.182L691.912,542.21Z" style="fill:white;stroke:black;stroke-width:8px;")
          text.symbol(x="730" y="760") {{ symbols[1] }}
          text.number(x="780" y="510") 2
        g#case_03.case(@click="changeSymbol(2)")
          path(d="M941,542.21L1258.2,542.21L1224.7,786.182L1018.33,786.182L941,542.21Z" style="fill:white;stroke:black;stroke-width:8px;")
          text.symbol(x="1035" y="760") {{ symbols[2] }}
          text.number(x="1080" y="510") 3
        g#case_04.case(@click="changeSymbol(3)")
          path(d="M1290.822,542L1505.915,542L1582.112,786L1258.196,786L1290.822,542Z" style="fill:white;stroke:black;stroke-width:8px;")
          text.symbol(x="1330" y="760") {{ symbols[3] }}
          text.number(x="1375" y="510") 4
      button.validate(@click="validate") {{ $t('combat.valider') }}
    .text-block.text-victoire(v-else-if="status === 'victoire1'" v-html="$t('combat.victoire1')")
    .text-block.text-victoire(v-else-if="status === 'victoire2'" v-html="$t('combat.victoire2')")
    .text-block.text-erreur(v-else-if="status === 'erreur'" @click="reset" v-html="$t('combat.erreur')")
</template>

<script>
import MenuButton from '@/components/MenuButton.vue'

const CODE_VICTOIRE_1 = '2154'
const CODE_VICTOIRE_2 = '7993'

export default {
  name: 'VueCombat',

  components: {
    MenuButton
  },

  data () {
    return {
      symbols: [0, 0, 0, 0],
      status: 'play'
    }
  },

  methods: {
    changeSymbol (n) {
      if (this.symbols[n] === 9) {
        this.$set(this.symbols, n, 0)
      } else {
        this.$set(this.symbols, n, this.symbols[n] + 1)
      }
    },

    reset() {
      this.status = 'play'
      this.symbols = [0, 0, 0, 0]
    },

    validate () {
      const valStr = this.symbols.join('')
      if (valStr === CODE_VICTOIRE_1) {
        this.$sound('success')
        this.status = 'victoire1'
      } else if (valStr === CODE_VICTOIRE_2) {
        this.$sound('success')
        this.status = 'victoire2'
      } else {
        this.$sound('error')
        this.status = 'erreur'
      }
    }
  }
}
</script>

<style lang="less">
.vue-combat {
  h2 {
    margin: 2.5em auto;
    transform: rotate(-2.5deg);
    font-size: 34pt;
    font-weight: bold;
    width: 50%;
    padding: 0.5em;
  }
  button.validate {
    position: absolute;
    bottom: 4em;
    left: 50%;
    transform: translateX(-50%)
  }
  #menu-button {
    right: 2em;
    top: 0.5em;
  }
  svg text.symbol {
    font-family: Bionicle;
    font-size: 237px;
    fill: #FDB914;
    stroke: #FDB914;
    stroke-width: 2px;
  }
  svg text.number {
    font-size: 70px;
    fill: white;
  }
  svg .case {
    cursor: pointer;
  }

  .text-victoire,
  .text-erreur {
    position: absolute;
    width: 60%;
    left: 20%;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
